import { Module } from 'vuex'
import { RootState } from '@/store/index'
import { messageError, messageSuccess } from '@/utils/notify'
import { StatusResult } from '@/entity/Status/Result/StatusResult'
import { createStatus, deleteStatus, retrieveStatus, updateStatus } from '@/utils/http/LookupApi'
import { AddStatusRequest } from '@/entity/Status/Request/AddStatusRequest'
import { EditStatusRequest } from '@/entity/Status/Request/EditStatusRequest'

interface State {
  statusList: StatusResult[]
}

export const statusModule: Module<State, RootState> = {
  namespaced: true,
  state: () => ({
    statusList: []
  }),
  mutations: {
    init (state, payload: StatusResult[]) {
      state.statusList = payload
    },
    add (state, payload: StatusResult) {
      state.statusList.push(payload)
    },
    modify (state, payload: StatusResult) {
      const index = state.statusList.findIndex(v => v.id === payload.id)
      state.statusList.splice(index, 1, payload)
    },
    remove (state, id: number) {
      const index = state.statusList.findIndex(v => v.id === id)
      state.statusList.splice(index, 1)
    }
  },
  actions: {
    init (context) {
      retrieveStatus()
        .then(r => {
          context.commit('init', r.data)
        })
        .catch(e => {
          messageError(e.toLocaleString())
          console.log(e)
        })
    },
    add (context, payload: AddStatusRequest) {
      createStatus(payload)
        .then(r => {
          context.commit('add', r.data)
          messageSuccess('成功添加状态信息: ' + r.data.statusName)
        })
        .catch(e => {
          messageError(e.toLocaleString())
          console.log(e)
        })
    },
    modify (context, payload: EditStatusRequest) {
      updateStatus(payload)
        .then(r => {
          context.commit('modify', r.data)
          messageSuccess('成功修改状态信息')
        })
        .catch(e => {
          messageError(e.toLocaleString())
          console.log(e)
        })
    },
    remove (context, id: number) {
      deleteStatus(id)
        .then(r => {
          context.commit('remove', id)
          messageSuccess('成功删除状态信息')
        })
        .catch(e => {
          messageError(e.response.data)
          console.log(e)
        })
    }
  }
}
